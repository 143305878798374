<template>
  <div class="PolicyBox">
    <h2>《悦淘账户注销须知》</h2>
    <p>亲爱的悦淘个人消费者用户：</p>
    <p>您在申请注销流程中点击同意前，应当认真阅读《悦淘账户注销须知》（以下称“《注销须知》”）。
      请您务必审慎阅读、充分理解《注销须知》中相关条款内容，其中包括：</p>
    <p>1、与您约定免除或限制责任的条款；</p>
    <p>2、其他以粗体标识的重要条款。</p>
    <p>如您对本《注销须知》有任何疑问，可拨打我们的客服电话4001109600联系客服，您也可联系在线客服自助查询</p>
    <p><strong>【特别提示】当您按照注销页面提示填写信息、阅读并同意本《注销须知》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账户注销程序。</strong></p>
    <p><strong>我们在此善意地提醒您，您注销账户的行为会给您的售后维权带来诸多不便，且注销悦淘账户后，您的个人信息我们会在悦淘商城的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。</strong></p>
    <p><strong>1、如果您仍执意注销账户，您的账户需同时满足以下条件：</strong></p>
    <p>（1）自愿放弃账户在悦淘商城系统中的资产和虚拟权益（包括但不限于账户余额、悦豆、优惠券、悦淘账户权益等）；</p>
    <p>（2）账户内无未完成的订单和服务；</p>
    <p>（3）在悦淘商城没有过使用该账户激活店铺的记录；</p>
    <p>（4）账户无正在进行中的投诉举报或被投诉举报；</p>
    <p>（5）账户对应的悦淘支付账户已注销，且已妥善处理了悦淘支付账户下的所有问题；</p>
    <p>（6）账户已经解除与其他网站、其他APP的授权登录或绑定关系；</p>
    <p>（7）账户当前为有效账户，非冻结状态；</p>
    <p>（8）实名账户已解除实名信息。</p>
    <p>2、悦淘账户一旦被注销将不可恢复，请您在操作之前自行备份悦淘账户相关的所有信息和数据。请您保存好订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受售后服务。</p>
    <p>3、如果您的悦淘账户同时是悦淘平台商家店铺的绑定账户名，您需先解除相关绑定；</p>
    <p>4、在悦淘账户注销期间，如果您的悦淘账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，悦淘有权自行终止本悦淘账户的注销而无需另行得到您的同意。</p>
    <p><strong>5、注销悦淘账户，您将无法再使用本悦淘账户，也将无法找回您悦淘账户中及与账户相关的任何内容或信息，包括但不限于：</strong></p>
    <p>（1）您将无法登录、使用本悦淘账户；</p>
    <p>（2）本悦淘账户的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息等）都将无法找回；</p>
    <p>（3）您通过悦淘账户使用、授权登录或绑定悦淘账户后使用的悦淘相关或第三方的其他服务（包括但不限于团油、小桔加油、美团点餐、饿了么等）的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得的余额、优惠券、积分、资格、订单、悦淘账户权益及其他卡券等视为您自行放弃，将无法继续使用。您理解并同意，悦淘无法协助您重新恢复前述服务。请您在提交注销申请前，务必先了解您须解绑的其他相关账户信息，具体可与我们的客服联系。</p>
    <p><strong>6、注销本悦淘账户并不代表本悦淘账户注销前的账户行为和相关责任得到豁免或减轻。</strong></p>
  </div>
</template>
<script>
export default {
  name: 'CancellationNotice'
}
</script>
<style lang="less" scoped>
h1, h2, h3, h4, h5 {
  font-size: initial;
}

.PolicyBox {
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  padding: 15px;

  p {
    margin-bottom: 15px;
  }

  h2 {
    margin-bottom: 15px;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 15px;

  }

  h4 {
    font-weight: bold;
    margin-bottom: 12px;
  }

  span {
    display: inline-block;
  }

  table {
    border: 1px solid;
    max-width: 100%;
  }

  th, td {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;

    p {
      margin-bottom: 0;
      word-break: break-all;
    }
  }
}

.t-i-1 {
  text-indent: 1em;
}

.t-i-2 {
  text-indent: 2em;
}
</style>
